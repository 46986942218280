import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './SearchComponent.scss';
import SearchService from 'services/search.service';

type TSearchResult = {
  id: number;
  title: string;
  sourceTable: string;
  recordId: string;
};

const Search: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<TSearchResult[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const searchBoxRef = useRef<HTMLDivElement | null>(null);

  const handleSearchClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      if (value.trim()) {
        performSearch(value.trim());
      } else {
        setResults([]);
      }
    }, 500);
    setTypingTimeout(timeout);
  };

  const performSearch = async (searchQuery: string) => {
    setIsLoading(true);
    try {
      const response = await SearchService.search(searchQuery);
      setResults(response.data);
    } catch (error) {
      console.error('Ошибка запроса:', error);
      setResults([]);
    }
    setIsLoading(false);
  };

  const linkAndLabelMap: Record<string, { link: (recordId: string) => string, label: string }> = {
    blog: {
      link: (recordId) => `/blog/${recordId}`,
      label: 'Блог - ',
    },
    tour: {
      link: (recordId) => `/tours/${recordId}`,
      label: 'Тур - ',
    },
    partner: {
      link: (recordId) => `/partners/${recordId}`,
      label: 'Партнёр - ',
    },
    guidebook: {
      link: (recordId) => `/travel-guides/${recordId}`,
      label: 'Путеводитель - ',
    },
  };

  const generateLink = (sourceTable: string, recordId: string): string => {
    return linkAndLabelMap[sourceTable]?.link(recordId) || '#';
  };

  const getLabel = (sourceTable: string): string => {
    return linkAndLabelMap[sourceTable]?.label || '';
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="search-component">
        <div className="search-icon" onClick={handleSearchClick}>
          🔍
        </div>
      </div>
      <div className={classNames('search-box', { open: isOpen })} ref={searchBoxRef}>
        <input
          type="text"
          className={classNames('search-input', { 'search-input--open': isOpen })}
          value={query}
          onChange={handleInputChange}
          placeholder="Введите запрос"
        />
        {isLoading && <div className="loading-spinner">Загрузка...</div>}
        {results.length > 0 && (
          <ul className="search-results">
            {results.map((result) => (
              <li key={result.id} className="search-result-item">
                <a href={generateLink(result.sourceTable, result.recordId)}>
                  <strong>{getLabel(result.sourceTable)}{result.title}</strong>
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default Search;
