import { Link } from 'react-router-dom';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { TrkCardsList } from './TrkCardsList/TrkCardsList';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';

export const HomeTrk = () => {
  return (
    <section className='home-trk'>
      <div className='container home-trk__container'>
        <TitleSectionMain
          className='home-trk__title'
          title={
            <>
              Туркластер <span className='red'>«Арктический»</span>
            </>
          }
          subtitle='ТРК «Арктический» объединяет предпринимателей в сфере туризма с 2019 года. Наша цель — сделать Таймыр популярным местом для туризма жителей России и других стран.'
        />
        <TrkCardsList />
        <Link className='home-trk__btn' to={'/trk-info'}>
          Подробнее
          <ArrowButton />
        </Link>
      </div>
    </section>
  );
};
