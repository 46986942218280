

import { api as apiNoAuth } from 'http/commonApi';

type TSearchResult = {
  id: number;
  title: string;
  sourceTable: string;
  recordId:string;
}

export default class SearchService {
  static async search(query: string) {
    return apiNoAuth().get<TSearchResult[]>(`/search?query=${query}`);
  }
}
