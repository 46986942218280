import { ReactComponent as PeopleIcon } from 'assets/images/homePage/trk/people.svg';
import { ReactComponent as CarIcon } from 'assets/images/homePage/trk/car.svg';
import { ReactComponent as EverestIcon } from 'assets/images/homePage/trk/everest.svg';
import { ReactComponent as ManIcon } from 'assets/images/homePage/trk/man.svg';
import { ReactComponent as MountainIcon } from 'assets/images/homePage/trk/mountain.svg';
import { ReactComponent as BuildIcon } from 'assets/images/homePage/trk/build.svg';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';

export const TrkCardsList = () => {
  return (
    <div className='home-trk__cards-list'>
      <div className='home-trk__card-item'>
        <div className='card-item__icon'>
          <PeopleIcon />
        </div>
        <div className='card-item__content'>
          <TitleSectionMain
            title={
              <>
                10 000<span>+</span>
              </>
            }
            isSmall
            className='card-item__value'
          />
          <div className='card-item__label'>туристов в год</div>
        </div>
      </div>
      <div className='home-trk__card-item'>
        <div className='card-item__icon'>
          <ManIcon />
        </div>
        <div className='card-item__content'>
          <TitleSectionMain title='9' isSmall className='card-item__value' />
          <div className='card-item__label'>туроператоров</div>
        </div>
      </div>
      <div className='home-trk__card-item'>
        <div className='card-item__icon'>
          <MountainIcon />
        </div>
        <div className='card-item__content'>
          <TitleSectionMain
            title={
              <>
                50<span>+</span>
              </>
            }
            isSmall
            className='card-item__value'
          />
          <div className='card-item__label'>реализованных проектов</div>
        </div>
      </div>
      <div className='home-trk__card-item'>
        <div className='card-item__icon'>
          <BuildIcon />
        </div>
        <div className='card-item__content'>
          <TitleSectionMain
            title={
              <>
                10<span>+</span>
              </>
            }
            isSmall
            className='card-item__value'
          />
          <div className='card-item__label'>гостиниц</div>
        </div>
      </div>
      <div className='home-trk__card-item'>
        <div className='card-item__icon'>
          <CarIcon />
        </div>
        <div className='card-item__content'>
          <TitleSectionMain
            title={
              <>
                60<span>+</span>
              </>
            }
            isSmall
            className='card-item__value'
          />
          <div className='card-item__label'>туристических продуктов</div>
        </div>
      </div>
      <div className='home-trk__card-item'>
        <div className='card-item__icon'>
          <EverestIcon />
        </div>
        <div className='card-item__content'>
          <TitleSectionMain
            title={
              <>
                150<span>+</span>
              </>
            }
            isSmall
            className='card-item__value'
          />
          <div className='card-item__label'>мест отдыха</div>
        </div>
      </div>
    </div>
  );
};
